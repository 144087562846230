//
// Forms.scss
//

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left /*rtl: right*/;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl: right*/;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: var(--#{$prefix}secondary-bg);

    &:active {
      filter: none;
    }

    &:checked {
      background-color: var(--#{$prefix}secondary-bg) !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\F012C";
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: var(--#{$prefix}body-color);
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: var(--#{$prefix}secondary-bg);
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: var(--#{$prefix}secondary-bg) !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "";
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;

  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;

  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

select::invalid {
  color: var(--bs-gray-500);
}

// Customize the ng-select inside of the .form-floating class
.form-floating-ng-select {
  ng-select * {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  ng-select {
    height: initial !important;
    padding: 0.47rem 0.75rem 0.47rem 0.75rem;

    .ng-select-container {
      height: auto;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      background-color: transparent !important;
      box-shadow: none;
      min-height: initial;
      outline: none !important;
    }

    .ng-value-container {
      padding: 2px 8px 0 0 !important;
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      display: none;
    }
  }
}

.form-control.form-select {
  background-image: none;
}

textarea.auto-size {
  height: auto !important;
}
.ck.ck-powered-by {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
