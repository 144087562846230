.image-selection {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  i {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    transform: translateZ(10px);
  }
  .img-edit {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:hover {
    cursor: pointer;

    .img-edit {
      display: flex;
      color: #fff;
      font-size: 0.8rem;
      background: rgba(0, 0, 0, 0.5);
      i {
        color: #fff;
        font-size: 30px;
        margin: 0 auto;
        display: block;
        margin-top: 50%;
      }
    }
  }
}
