//
// Range slider
//


.ngx-slider {
  .ngx-slider-pointer {
    position: absolute !important;
    display: block !important;
    transform: rotate(45deg) !important;
    border: 2px solid #556ee6 !important;
    border-radius: 0% !important;
    width: 12px !important;
    height: 12px !important;
    top: -4px !important;
    background-color: var(--bs-secondary-bg) !important;
    cursor: pointer;
  }

  .ngx-slider-bubble {
    font-size: 0.8125rem !important;
  }
}

.ngx-slider.animate .ngx-slider-selection {
  background-color: #556ee6 !important;
}