/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
@import "~dropzone/dist/min/dropzone.min.css";

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}

//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: none !important;

  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

.dropzone:not(body[data-bs-theme="dark"] .dropzone) {
  .dropzone {
    border: 2px dashed rgb(239, 242, 247, 0.5) !important;
    background: #fff !important;
  }
}

body[data-bs-theme="dark"] {
  .dropzone {
    background-color: var(--bs-dark-body-bg);
    border: 2px dashed var(--bs-dark-body-bg);
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone > .dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem;
}

dropzone > .dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}

#component-colorpicker {
  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small > .switch-pane > span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;

  li.slick-active button:before {
    color: #556ee6 !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: #556ee6;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-160px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: #556ee6 !important;
}

.sortable,
.expand-row,
.fa-eye,
.fa-edit {
  cursor: pointer;
}

.row-header {
  td {
    font-weight: 600;

    background-color: rgb(239, 242, 247) !important;
    border-bottom-color: rgb(215, 218, 222) !important;
  }
}

body[data-bs-theme="dark"] {
  .row-header {
    td {
      background-color: rgb(52, 58, 64) !important;
      border-bottom-color: rgb(52, 58, 64) !important;
    }
  }
}

.breadcrumb-item {
  a {
    letter-spacing: 0.015em;
    &:hover {
      font-weight: 600;
      cursor: pointer;
      letter-spacing: normal;
    }
  }
}

td:not(.cell-full-width) {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
}

td.action {
  overflow: visible;
  .dropdown-menu {

    &.show {
          display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: initial;
    }

    .dropdown-item {
      font-family: var(--bs-font-monospace);
      font-size: 87.5%;
      text-transform: uppercase;
      opacity: 0.7;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      width: initial;
      &:hover {
        opacity: 1;
      }
      i {
        &:hover {
          color: var(--bs-primary) !important;
        }
      }
    }
  }
}

td:not(.multiline) {
  white-space: nowrap;
}

table[data-cols="10"] {
  td:not(.action) {
    width: 10%;
  }
}
table[data-cols="9"] {
  td:not(.action) {
    width: 11%;
  }
}
table[data-cols="8"] {
  td:not(.action) {
    width: 12%;
  }
}
table[data-cols="7"] {
  td:not(.action) {
    width: 14%;
  }
}
table[data-cols="6"] {
  td:not(.action) {
    width: 15.75%;
  }
}
table[data-cols="5"] {
  td:not(.action) {
    width: 18%;
  }
}

.form-floating > label {
  padding: 0.75rem 0 0 0.25rem;
  height: initial;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(2.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-select {
  padding: 0 0.4rem;
  background-position: right 0.25rem center;
}

.form-select.heading {
  padding: 0 1rem 0 0.5rem;
  background-position: right 0.25rem center;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 0.75rem 0.4rem;
}

.form-floating > .form-select {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-switch {
  min-height: 18px;
  line-height: 18px;
  padding-bottom: 0;
  margin-bottom: 0;
  label {
    font-size: 0.75rem;
  }
}

.form-switch-md .form-check-input {
  width: 30px;
  height: 15px;
  left: -0.5rem;
  position: relative;
}

.ck-editor__editable {
  min-height: initial !important;
}

.ck-editor__editable,
.ck-content {
  min-height: 100px !important;
  max-height: 200px !important;
}

.h {
  .ck-editor__editable,
.ck-content {
  min-height: 250px !important;
  max-height: 300px !important;
}
}

.avatar-md {
  width: 100px !important;
}

.rounded-start {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-end {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.dropdown-menu.show {
  max-height: 500px;
  overflow-y: scroll;
}

.bl-0 {
  border-left: 0px !important;
}

.br-0 {
  border-right: 0px !important;
}

.mode-select {
  // button {
  //   border: 0px !important;
  // }
  // button + button {
  //   border-left: 1px solid var(--bs-border-color) !important;
  // }
  div {
    .dropdown-toggle {
      button:not(:last-child) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      button:last-child {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      button:not(:first-child) {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      button:first-child {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      button:not(:first-child):not(:last-child) {
        border-radius: 0px !important;
      }
    }
  }
}

.disabled:not(pagination .disabled) {
  // background-color: var(--bs-topbar-search-bg);
  pointer-events: none;
}

.form-floating ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background: none !important;
}

.avatar-lg {
  max-width: 150px;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 150px;
  background-color: var(--bs-body-bg);
  padding: 12px 6px;
  border-radius: 5px;
  cursor: pointer;
  i {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
  }
  &.h {
    max-height: 300px;
  }
}


table  td i {
  &:hover {
    cursor: pointer;
  }
}

.sortable {
  &:hover {
    cursor: pointer;
  }
}

tr.clickable {
  &:hover {
    background-color: var(--bs-table-hover-bg);
  }
}

td   .expand-row {
  width: 100%;
  max-width: 25px;
  border: solid 1px #596269;
  display: inline-block;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: top;
  background: var(--bs-body-bg);
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
} 

.icon-cell {
  width: 25px !important;
  max-width: 40px !important;
  min-width: 25px !important;
  // display: inline-block;
  // justify-content: center;
  // align-items: center;
   &:hover {
    cursor: pointer;
  }
}

.card {
  box-shadow: none !important;
}

.disabled label {

}

.ao-form {
  input:not(input[type="checkbox"]):not(input[type="radio"]), select {
    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
}

input.disabled, select.disabled {
  background: none;
  border-radius: 0 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: var(--bs-body-bg);
}

input.disabled + label {
  bottom: -38px;
}

input.readonly, select.readonly {
  background: none !important;
}

.ao-form-read {
  input:not(input[type="checkbox"]), select {
    background: none;
    border-radius: 0 !important;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none !important;
    pointer-events: none;
  }
  input[type="checkbox"] {
    pointer-events: none;
  }
}