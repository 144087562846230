//
// Form editors.scss
//

// Tinymce

form {
    .tox-tinymce {
        border: 1px solid $border-color-translucent;
    }

    .tox {

        :not(svg):not(rect) {
            color: $body-color;
        }

        &:not(.tox-tinymce-inline) {
            .tox-editor-header {
                box-shadow: var(--#{$prefix}box-shadow);
            }
        }

        .tox-mbtn {
            color: $body-secondary-color;

            &:hover:not(:disabled):not(.tox-mbtn--active) {
                background-color: $body-tertiary-bg;
            }
        }

        .tox-tbtn {

            &:hover {
                color: $body-tertiary-color;
                background-color: $body-tertiary-bg;
            }
        }

        .tox-tbtn--disabled svg,
        .tox-tbtn--disabled:hover svg,
        .tox-tbtn:disabled svg,
        .tox-tbtn:disabled:hover svg {
            fill: $body-tertiary-color;
        }

        .tox-tbtn--bespoke {
            background-color: $body-tertiary-bg;
        }
    }
}

.ck-reset,.ck-input{
    background-color: var(--#{$prefix}secondary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
}

.ck-link-form{
    background-color: var(--#{$prefix}secondary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
}
.ck.ck-balloon-panel{
    border-color: var(--#{$prefix}border-color) !important;
}

.ck.ck-balloon-panel[class*=arrow_n]:after{
    border-color: var(--#{$prefix}border-color) !important;
}
